<template>
    <split-pane  :min-percent='10' :default-percent='15' split="vertical">
        <template slot="paneL">
            <RadioGroup v-model="rgflag" type="button" style="margin-top:3px;margin-bottom:4px;" @on-change="rgchange">
                <Radio label="loop">
                    <span>递归查询</span>
                </Radio>
                <Radio label="one">
                    <span>单层查询</span>
                </Radio>
            </RadioGroup>
            <el-tree :data="treeData" 
                        :props="defaultProps" 
                        @node-click="handleNodeClick" 
                        node-key="id"      
                        :default-expanded-keys="['0']"     
                        v-loading="loadingtree"
                    element-loading-text="加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 251, 255, 0.8)"               
                        :style="{background:'#fff',overflow:'auto',height: scrollerHeight}"
                        highlight-current
                        @node-contextmenu="rightClick"   
                        :filter-node-method="filterNode"
                        ref="eltree"
                        :expand-on-click-node="false">
                <template v-slot="{node,data}">                                     
                    <span v-if="data.id ==0 "  class="el-icon-notebook-1 nodeRoot" >
                        <span class="nodeLabel">{{ node.label}} </span>
                    </span>                  
                    <span v-else class="el-icon-folder" > 
                        <span class="nodeLabel">{{ node.label}} </span>
                    </span>
                </template>
            </el-tree>
            <div v-show="menuVisible" @mouseleave="menuVisible=!menuVisible">
                <ul id="menu">
                    <li tabindex="-1" class="menu__item" id="menu-4994-1-0" @click="() => treeRefresh(nodeData)">
                        <el-link icon="el-icon-refresh" :underline="false">刷新节点数据</el-link>
                    </li>
                </ul>
            </div>
        </template>
        <template slot="paneR"> 
            <div style="border-left:2px solid #409eff;height:35px;padding-left:1px;margin-top:3px;display:flex;background:white">
                <el-button @click="addRec()" type="text" icon="el-icon-plus" style="margin-right:30px;margin-top:-2px;margin-left:5px;"> 新增节点</el-button>                     
                <span style=" margin-left:20px;font-size:12px;vertical-align:middle;line-height:35px;margin-right:10px;">字段查询</span>
                <el-input @keyup.enter.native="qryTabData" :placeholder="input_hold" v-model="textparam" size="medium" style="width:400px; " >
                    <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px; ">
                        <span v-for="item in tabCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key"></el-option>
                        </span>
                    </el-select>
                </el-input> 
                <el-link  @click="expExcel" :underline="false">
                    <i class="iconfont icon-daochu1" style="font-size:17px;  margin-left:25px;color:#409eff"></i>
                    <span style="font-size:13px;  font-weight:600;margin-left:5px;">导出EXCEL</span>
                </el-link>                           
            </div>       
            <div>
            <el-table  :data="baseData" 
                    border                            
                    disabled-hover
                    style="overflow:auto;margin-top:1px;"
                    v-loading="loading"
                    :row-key="getRowKeys"
                    size="small"
                    ref="tabref"
                    :height="tabHeight" 
                    highlight-current-row
                    @row-click="rowClick"  >
                <span v-for="(item,index ) in tableCol" :key="index" >    
                    <el-table-column   v-if ="item.key!=='ICON'"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column>
                    <el-table-column  v-if ="item.key==='ICON'"
                        :prop="item.key"
                        :label="item.title"
                        :align="item.align"                                  
                        :min-width="item.width">
                        <template  slot-scope="scope" >
                            <el-link @click.native="imgPreview(scope.row)" :underline="false">
                                <!--logo图-->
                                <el-image fit="fill" style="width:50px;height:25px;vertical-align:center;text-align:center; " :src="scope.row.ICON"></el-image>
                            </el-link>
                        </template>
                    </el-table-column>
                </span>  
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="210">
                    <template slot-scope="scope">
                        <Upload
                            ref="upload"
                            name="upfile"
                            :data="uploadData"
                            :show-upload-list="false"
                            :before-upload="beforeImgFile"
                            :on-success="successImgFile"
                            :accept="Accept"                           
                            :format="['jpg','jpeg','png']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-exceeded-size="handleMaxSize"
                            type="drag"
                            :action="uploadUrl"
                            style="display: inline-block;width:70px;margin-right:10px;vertical-align:middle; ">                           
                            <!-- <Button icon="ios-cloud-upload-outline" :disabled="btnupload" >上传图片</Button> -->
                            <el-link :underline="false" style="font-size:10px;">上传图片</el-link>                                                        
                        </Upload>
                        <el-button size="small"   type="text" icon="el-icon-edit" @click="editRec(scope.row,scope.$index)">编辑</el-button>
                        <el-button size="small"   type="text" icon="el-icon-close" @click="delRec(scope.row,scope.$index)">删除</el-button>   
                    </template>
                </el-table-column>                         
            </el-table>
            </div>
            <div style="margin-top: 2px;margin-bottom:2px;overflow: hidden;background:white">
                <div style="float: left;">
                    <Page :total="dataCount" :page-size="pageSize" show-total :current.sync="page" @on-change="changePage"></Page>
                </div>
            </div>
            <Modal v-model="addModal"  :closable="false"  scrollable  width="820">
                <Form ref="addForm" :model="formCol" :label-width="100"  :rules="ruleValidate"  label-position="right" inline >
                    <span v-for="(item,index) in tabCol" :key="index" >
                        <FormItem :label="item.FLDNM" :prop="item.FLDNO" v-if="item.FHIDE==='N'">                                  
                            <Input type="text" :disabled="item.DISABLED==='Y'?true:false" v-model="formCol[item.FLDNO]"  style="width:140px;" clearable/>
                        </FormItem>
                    </span>
                </Form>                     
                <div slot="footer" style="height:24px;">
                    <Button type="primary" style="margin-right:25px;"  @click="addModal=false">取消</Button>
                    <Button type="primary"   @click="modal_ok">确定</Button>
                </div>
                <div slot="header" style="height:10px;line-height:10px">
                    <span style="font-weight:600;font-size:14px;color:#ccc">{{type==='create'?'新增记录':'修改记录'}}</span>
                </div>
            </Modal>
            <Modal v-model="imgModal" scrollable  width="560" :footer-hide="true">
                <el-image fit="scale-down" style="width:540px;height:500px;" :src="imgsrc"></el-image>
            </Modal>
            <Modal v-model="expModel" :closable="false" scrollable  width="101" :footer-hide="true">
                <el-image fit="scale-down" style="width:60px;height:60px;" src="/assets/authorize/loading.gif"></el-image>
            </Modal>
            <div>
                <el-image-viewer
                    v-if="showViewer"
                    :on-close="closeViewer"
                    :url-list="listurl"
                    style="width: 70%; height: 70%; margin-left: 10%; margin-top: 5%"/>
            </div>
        </template>
    </split-pane>
</template>

<script>
    import { getTabColOrData  } from '../../api/user';
    import {getDate} from '../../api/Select'
    //引入el-image-viewer 
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    export default {
        name: "base_dic",
        data(){
            return {
                treeData:[] ,//  树数据
                filterText: '',
                pageIndex:1,
                menuVisible:false,
                showViewer:false,//打开预览/关闭
                listurl:[],
                ruleValidate:{},
                nodeData:{},
                expModel:false,
                input_hold:'请输入内容...',
                defaultProps: {  //el-tree 默认字段属性
                    children: 'children',
                    id:'id',
                    icon:'icon',
                    label: 'label'
                },
                uploadData:{}, //上传数据
                Accept: "png,jpg,jpeg", //上传文件格式限制
                notNull:[],
                resultCode:{}, //状态码
                treeid :'0',
                type: 'create',
                rgflag:'one',
                formCol:{}, //表格单行记录 model
                addModal:false, //modal 
                loading:false,// table加载bool
                textparam:'', // input 绑定值
                selparam:'',// select 绑定值
                tabCol:[] ,//表列集合
                tableCol:[] ,//表列格式化集合
                baseData:[] ,//表数据
                allData:[] , //保存取到所有数据
                nodeLabel:'数据字典',
                rowIndex:'-1', //行号
                rowCode:'-1',
                loadingtree:false,
                stfgBool:true,
                page:1,
                // 初始化信息总条数
                dataCount:0,
                // 每页显示多少条
                pageSize:25,
                v_url:this.$store.state.queryUrl , //api请求路径
                v_urlcol:this.$store.state.baseUrl+'sysprivs/getFormColumn',
                v_urldata:this.$store.state.baseUrl+'sysprivs/getRecuriveRec', //api请求路径(递归查询)
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                v_requrl:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //动态请求url接口
                //uploadUrl:this.$store.state.baseUrl +'auth/login/uploadImg',
                uploadUrl: '',
                imgsrc:'',
                imgModal :false,
            }
        },
        created(){
            //初始化
            this.getData();
            this.getTabCol();
            this.getFormCol();
            this.getTabData('parentno','0','','','','','');
        },
        mounted(){
            this.uploadUrl =this.$store.state.baseUrl +'oss/fileUpload?username='+this.v_username+'&password='+this.v_password
            window.addEventListener("offline",()=>{
                this.$Message.error('已断网，请重新连接')
            })
            //动态生成表单验证规则
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','16','fty','HEAD','NOTNULL','Y').then((res) => {
                let ruleObj={}
                let ruleVal=[]
                this.notNull = res.data
                res.data.forEach(item => {
                    let v_fldnm =item.FLDNM
                    let obj1={required:true,message:v_fldnm+'不能为空'}
                    ruleVal =[]
                    ruleVal.push(obj1)
                    ruleObj[item.FLDNO]=ruleVal                  
                });
                this.ruleValidate =ruleObj
            });
            // 设置表格高度
            //this.tableHeight = window.innerHeight -240 // this.$refs.tabref.$el.offsetTop +160
    
        },
        computed:{
            scrollerHeight: function() {
                return (window.innerHeight - 140) + 'px';
            }, 
            tabHeight(){
                return (window.innerHeight - 160) + 'px';
            },
            btnupload: function(){
                if (this.rowIndex ==='-1' ) {
                    return true
                }else{
                    return false
                }
            }   
        },
        watch:{
            filterText(val) {
                this.$refs.eltree.filter(val);
            }
        },
        components: {
            ElImageViewer,
        },
        methods:{
            //导出 file-saver ,script-loader ,xlsx
            expExcel(){
               this.expModel =true
               require.ensure([], () => {
                    const { export_json_to_excel } = require('../../plugin/Export2Excel');
                    let tHeader = [] ,filterVal=[]
                    for(let k=0 ;k<this.tableCol.length;k++){
                        tHeader.push(this.tableCol[k].title) //设置Excel的表格第一行的标题
                        filterVal.push(this.tableCol[k].key) //tableData里对象的属性
                    }
                    const list = this.allData;  //把data里的invData存到list
                    const data = this.formatJson(filterVal, list);
                    const fileName='基础数据_'+getDate()
                    export_json_to_excel(tHeader, data, fileName);
                    this.expModel =false
                })
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => v[j]))
            },
            getRowKeys(row){
                return row.IDSEQ
            },
            //图片预览
            imgPreview(row){
                if (row.LOGO){
                    this.showViewer=true
                    this.listurl=[row.ICON]
                }
            },
            closeViewer(){ //关闭
                this.showViewer = false
            },
            // 文件上传成功时的钩子
            successImgFile(res,file){
                var vm =this
                vm.getTabData('parentno',vm.treeid,vm.selparam,vm.textparam,'','','')                    
                vm.$Message.info('上传成功')
            },
            //上传文件之前的钩子
            beforeImgFile (res, file) {
                this.uploadData = {
                    // code: this.rowCode ,
                    // p_table:'sys_base',  
                    p_user:this.v_username,
                    p_idseq:this.rowCode ,
                    p_table:'sys_base',
                    p_filety:'base',              
                }
                let promise = new Promise(resolve => {
                this.$nextTick(function() {
                        resolve(true);
                    });
                });
                return promise;
            },
            //文件格式验证错误时的钩子
            handleFormatError (file) {
                this.$Message.error('文件格式错误,'+file.name + ' 是不正确, 请选择 jpg or png' )
            },
            //文件超出指定文件大小限制的钩子
            handleMaxSize (file) {
                this.$Message.error(file.name + ' 文件太大, 不能超出2M' )
            },
            //树节点刷新
            treeRefresh(){
                //console.log(this.nodeData.data.id)
                this.getData();
            },
            //树右键功能
            rightClick(MouseEvent, object, Node, element) {
                this.nodeData = Node
                //debugger
                this.menuVisible = true;
                let menu = document.querySelector("#menu");
                menu.style.cssText = "position: fixed; left: " + (MouseEvent.clientX + 50) + 'px' + "; top: " + (MouseEvent.clientY - 25) + 'px; z-index: 999; cursor:pointer;';
            },
            //节点过滤
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //节点点击
            handleNodeClick(data){
                this.nodeLabel=data.label;            
                this.treeid =data.id
                this.getTabData('parentno',data.id,'','','','','');
            },
            //获取表行数据
            rowClick (row, index) {
                this.formCol = JSON.parse(JSON.stringify(row))  //深拷贝  获取行数据
                this.rowIndex =index
                this.rowCode =row.CODE
            },
            //radio group change
            rgchange(){
                if (this.rgflag ==='loop'){
                    this.v_requrl =this.v_urldata
                }else{
                    this.v_requrl =this.v_url
                }
                this.getTabData('parentno',this.treeid,this.selparam,this.textparam,'','','');
            },
            //确定
            modal_ok(){
                var vm=this;
                this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                    if (!this.formCol[item.FLDNO] ){
                       this.stfgBool =false 
                       this.$Message.error(item.FLDNM+'不能为空'); 
                       return true
                    }else{
                      this.stfgBool =true  
                    }
                })
                //发起保存请求   
                if (this.stfgBool) 
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_json:JSON.stringify(vm.formCol), p_table:'sys_base'},
                    }).then(res=>{      
                        if(res.data.result =='OK')  {          
                            vm.addModal =false   
                            vm.getTabData('parentno',vm.treeid,vm.selparam,vm.textparam,'','','')                           
                            //vm.getData()       
                        }else{
                            this.$Message.error(res.data.result)
                        }        
                    }) 
                //}
            } ,
            //新增
            addRec(){
                this.addModal =true
                this.type ='create'
                this.formCol ={}
                this.rowIndex ='-1'
                this.formCol.PARENTNO =this.treeid
                this.formCol.PCNAME =this.nodeLabel
                
            },
            //修改
            editRec(){
                this.addModal =true
                this.type ='edit'
            },
            //删除
            delRec(){
                if (this.rowCode==='0'){
                    this.$Message.error('根结点不能删除')
                }
                else{
                    this.$Modal.confirm({
                        title: '删除确认',
                        content: '<p>你确定要删除此行记录吗</p>',
                        onOk: () => {
                            //发起删除请求
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                                data: {p_key:'code',p_value:this.rowCode,p_table:'sys_base'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {           
                                    vm.getTabData('parentno',vm.treeid,vm.selparam,vm.textparam,'','','')
                                    //vm.getData()       
                                }else{
                                    this.$Message.error(res.data.message)
                                }        
                            }) 
                        }
                    });
                }
            },
            //表格页面导航
            changePage(index){
                this.pageIndex =index
                var _start = ( index - 1 ) * this.pageSize;
                var _end = index * this.pageSize;
                this.baseData = this.allData.slice(_start,_end);
                
            },
            // el-select 改变时 影响el-input 的 placeholder
            elchange(val){  // 这个val 就是 el-select 的 value
                let obj ={}
                obj =this.tabCol.find(item=>{
                    return item.key===val
                })
                if (obj)
                this.input_hold='请输入'+obj.title+' 的值'
            },
            //查询事件
            qryTabData(){
                //console.log('....'+this.selparam+'::'+this.textparam)
                this.getTabData('parentno',this.treeid,'','','','',' and '+this.selparam+' like \'%'+this.textparam+'%\'');
            },
            // 获取 树形控件数据
            getData() {  
                var vm=this;
                this.loadingtree=true
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_table:'v_sys_base'}
                }).then(function(res){                  
                    vm.treeData =res.data;   
                    if(res.data.length > 0 ){
                        //'nextTick()' 下次dom更新时触发回调函数
                        vm.$nextTick().then(() =>{
                            //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                            vm.$refs.eltree.setCurrentKey(vm.treeid)
                        })
                    }
                    vm.loadingtree=false               
                })                              
            },
            //获取表列头字段
            getTabCol(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','16','fty','HEAD','thide','N',' order by sortby,lstseq').then((res) => {
                    vm.tabCol = res.data
                    for (let k=0; k<res.data.length; k++){
                        vm.tableCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            sortable: res.data[k].sortable?true:false,
                        })
                    }
                });
            },
            //获取表单字段
            getFormCol(){
                var vm=this
                getTabColOrData(vm.v_urlcol,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','16','fty','HEAD','thide','N','').then((res) => {
                    vm.formCol = res.data
                });
            },
            //获取表数据
            getTabData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,v_cond){
                var vm=this
                this.loading =true
                getTabColOrData(this.v_requrl,this.v_username,this.v_password,'v_sys_base',v_key,v_value,v_key2,v_value2,v_key3,v_value3,v_cond).then((res) => {
                    vm.baseData = res.data
                    //保存取到 所有数据
                    vm.allData =res.data
                    vm.dataCount =res.data.length //总条数
                    // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                    if (res.data.length < vm.pageSize){
                        vm.baseData = vm.allData
                    }else{
                        vm.baseData =vm.allData.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                    }
                    //查询后回到当前页
                    vm.changePage(vm.pageIndex)
                    vm.current =Number(vm.pageIndex)
                    this.loading =false
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        this.$refs.tabref.doLayout();
                    })
                });
                
            }
        }
    }
</script>
<style lang="less" scoped>
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
</style>
<style lang="less"  >
    .menu__item {
        width: 100%;
        display: block;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        text-align: center;

    }
</style>
